.MuiPickersBasePicker-container {
  background-color: var(--date-picker-container-background);
  color: var(--ion-text-color-contrast);
  .MuiTypography-body1{
    font-family: var(--headers-font-family);
  }
  .MuiTypography-body2{
    font-family: var(--headers-font-family);
  }
  .MuiPickersBasePicker-pickerView {
    .MuiPickersCalendarHeader-switchHeader {
      margin: 0px;
      padding: 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(var(--ion-text-color-contrast-rgb), 0.1);
      .MuiPickersCalendarHeader-iconButton {
        background: var(--date-picker-header-button-background) !important;
        color: var(--ion-text-color-contrast) !important;
        opacity: 1;
        border-radius: 4px;
      }
    }
    .MuiPickersCalendarHeader-daysHeader {
      .MuiPickersCalendarHeader-dayLabel {
        padding-top: 16px;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        text-transform: capitalize;
        color: var(--ion-color-primary) !important;
        font-family: var(--headers-font-family);
      }
    }
    .MuiPickersCalendar-transitionContainer {
      .MuiIconButton-root {
        color: var(--ion-text-color-contrast) !important;
        opacity: 1;
        border-radius: 4px;
        font-family: var(--headers-font-family);
      }
      .MuiPickersDay-hidden {
        color: var(--ion-text-color) !important;
      }
      .MuiPickersDay-daySelected {
        background: var(--ion-primary-gradient) !important;
        color: var(--ion-color-primary-contrast) !important;
      }
    }
  }
}
