@import '../src/assets/global_styles/index.scss';

.router-ctn {
  width: 100%;
  background-color: var(--site-background-color);
  min-height: 100%;
  @media screen and (max-width: 720px) {
    min-height: 100%;
  }
  ion-col{
    @media screen and (max-width: 720px) {
     padding: 0;
    } 
  }
}

.Toastify__toast-container--top-center {
  top: 3em;
}

html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: 'Alata';
}

.dev-tools-msg-modal {
  .modal-content-ctn {
    background: var(--ion-background) !important;
    border: 5px solid var(--ion-color-primary);

    .dev-tools-warning-msg {
      padding: 20px;
      font-size: 24px;
      color: #fff;
    }
  }
}

:host {
  --background: #0d192e;
}
