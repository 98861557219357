.rep-inner-ctn {
  background: var(--reports-background-color);
  padding: 16px;
  border-radius: 24px;
  .img-page-title-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 0px;
    align-items: center;
    .title-image {
      height: 28px;
      width: 28px;
      margin-right: 4px;
      margin-top: -3px;

      path {
        fill: var(--ion-color-primary);
      }
    }

    .title {
      font-size: 24px;
      font-family: var(--headers-font-family);
      margin: 8px 0px 16px 8px !important;
      display: flex;
      align-items: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      @media only screen and (max-width: 780px) {
        font-size: 20px;
        margin: 0 0px 0px 0px !important;
        padding: 5px 10px;
        background: var(--reports-background-color);
      }
      .back-arrow {
        display: none;
        @media screen and (max-width: 720px) {
          display: flex;
        }
      }
    }
    .left-mywallet-title {
      display: flex;
      align-items: center;
      .claim-btn {
        margin-left: 5px;
        color: var(--ion-text-color);
        border-radius: 100px;
        background: #18bb4e;
      }
      @media screen and (max-width: 720px) {
        background: var(--reports-background-color);
      }
      .back-arrow {
        display: none;
        @media screen and (max-width: 720px) {
          display: flex;
        }
      }
    }
    .header-ctn {
      &.my-wallet-header {
        .filters-row {
          .from-date-filter {
            position: relative;
            .MuiFormControl-marginNormal {
              border: 1px solid rgba(255, 255, 255, 0.14);
              background: rgba(255, 255, 255, 0.04);
              border-radius: 10px;
              margin: 0 0 0 6px;
              height: 40px;
            }
            .date-label {
              position: absolute;
              left: 23px;

              font-size: 10px;
              color: var(--profile-bot-color);
            }
          }
          .to-date-filter {
            position: relative;
            .MuiFormControl-marginNormal {
              border: 1px solid rgba(255, 255, 255, 0.14);
              background: rgba(255, 255, 255, 0.04);
              border-radius: 10px;
              margin: 0 0 0 6px;
              height: 40px;
            }
            .date-label {
              position: absolute;
              left: 23px;
              font-size: 10px;
              color: var(--profile-bot-color);
            }
          }
        }
      }
      .filters-row {
        .trans-type-filter {
          position: relative;
          @media screen and (max-width: 720px) {
            padding: 0px !important;
          }
          ion-select {
            width: 152px;
          }
          .select-label {
            position: absolute;
            font-size: 10px;
            left: 17px;
            color: var(--profile-bot-color);
            ion-select {
              width: 152px;
            }
          }
          ion-select {
            width: 152px;
            @media screen and (max-width: 720px) {
              width: 100%;
            }
          }
          @media screen and (max-width: 720px) {
            padding: 0 10px;
          }
        }
        .mobile-flex {
          display: flex;
          @media screen and (max-width: 720px) {
            margin: 20px 0;
            padding: 0px;
          }
          .from-date-filter {
            @media screen and (max-width: 720px) {
              margin-right: 6px;
            }
          }
        }
        .from-date-filter {
          position: relative;
          .MuiInputBase-root .MuiInputBase-input {
            font-size: 14px;
            color: white;
          }
          .date-label {
            position: absolute;
            left: 23px;

            font-size: 10px;
            color: var(--profile-bot-color);
          }
          @media screen and (max-width: 720px) {
            width: 100%;
          }
        }
        .to-date-filter {
          position: relative;
          .date-label {
            position: absolute;
            left: 23px;

            font-size: 10px;
            color: var(--profile-bot-color);
          }
          @media screen and (max-width: 720px) {
            width: 100%;
          }
        }
        .MuiFormControl-marginNormal {
          border: 1px solid rgba(255, 255, 255, 0.14);
          background: rgba(255, 255, 255, 0.04);
          border-radius: 10px;
          margin: 0 0 0 6px;
          width: 180px;
          @media screen and (max-width: 720px) {
            width: 100%;
            margin: 0 6px 0 0px;
          }
          .MuiInput-root {
            height: 40px;
          }
        }
      }
    }
    @media screen and (max-width: 720px) {
      display: initial;
      .header-ctn {
        margin: 12px 12px 12px 12px !important;
      }
    }
  }
  @media only screen and (max-width: 720px) {
    padding: 0px 0;
  }
  .filters-row {
    display: flex;
    flex-direction: row;
    .filters {
      display: flex;
      flex-direction: row;
      .myb-btn-filter {
        position: relative;
        .filter-title {
          position: absolute;
          color: #18bb4e;
          font-family: var(--headers-font-family);
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          left: 16px;
          .filter-bg-clr {
            position: relative;
          }
        }
        @media screen and (max-width: 720px) {
          width: 100%;
        }
        ion-select {
          @media screen and (max-width: 720px) {
            width: 100%;
          }
        }
      }
      .myb-btn-games {
        padding-left: 6px;
        position: relative;
        .filter-title {
          position: absolute;
          color: #18bb4e;
          font-family: var(--headers-font-family);
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          left: 23px;
          .filter-bg-clr {
            position: relative;
          }
        }
        @media screen and (max-width: 720px) {
          width: 100%;
        }
        ion-select {
          @media screen and (max-width: 720px) {
            width: 100%;
          }
        }
      }
      .filters-select {
        display: flex;
        flex-direction: row;
        .myb-date-div {
          .myb-date-label {
            position: absolute;
          }
        }
      }
      @media screen and (max-width: 720px) {
        padding: 12px;
      }
    }
    .filters-select {
      display: flex;
      .myb-date-div {
        position: relative;
        width: 180px;
        @media screen and (max-width: 720px) {
          width: 100%;
        }
        .myb-date-label {
          position: absolute;
          left: 23px;
          font-size: 10px;
          color: var(--profile-bot-color);
          .MuiInputAdornment-positionEnd {
            position: absolute;
            right: 0;
          }
        }
        .MuiFormControl-marginNormal {
          border: 1px solid rgba(255, 255, 255, 0.14);
          background: rgba(255, 255, 255, 0.04);
          border-radius: 10px;
          margin: 0 0 0 6px;
          height: 40px;
          @media screen and (max-width: 720px) {
            width: 98%;
          }
        }
        &:first-child {
          .MuiFormControl-marginNormal {
            @media screen and (max-width: 720px) {
              margin: 0;
              width: 100%;
            }
          }
        }
      }
      @media screen and (max-width: 720px) {
        margin: 0px 12px 12px 12px;
      }
    }
    @media screen and (max-width: 720px) {
      display: initial;
    }
  }
  @media screen and (max-width: 720px) {
    background: none;
  }
}
.reports-ctn {
  border-radius: 4px;
  background: var(--reports-background-color);
  border-radius: 24px;
  padding: 10px 16px 16px 16px;
  .header-ctn {
    ion-row {
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 20px;
    }

    .page-title {
      color: var(--ion-text-color);
      font-family: var(--headers-font-family);
      font-style: normal;
      letter-spacing: 0.01em;
      float: left;
      padding: 8px;
      font-weight: 400;
      font-size: 24px;
      line-height: 99%;
      display: flex;
      align-items: center;

      @media screen and (min-width: 920px) {
        &.button-page-title {
          img {
            padding-right: 10px;
          }
        }
      }

      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 4px;
        margin-top: -3px;

        path {
          fill: var(--ion-color-primary);
        }

        @media screen and (max-width: 920px) {
          width: 21px;
          height: 21px;
        }
      }
    }

    .img-page-title-row {
      display: inline-flex;
      align-items: center;
      line-height: 42px;
      margin-right: auto;

      .title-image {
        height: 28px;
        width: 28px;
        margin-right: 4px;
        margin-top: -3px;

        path {
          fill: var(--ion-color-primary);
        }
      }

      .title {
        font-family: var(--headers-font-family);
        color: var(--ion-color-primary);
        font-size: 24px;
        line-height: 99%;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }

  .filters-row {
    ion-select,
    .date-control {
      border: 1px solid var(--filter-controls-border);
    }

    .filter-label {
      color: var(--input-text-color);
    }

    .MuiInputBase-root .MuiInputBase-input {
      padding: 0 4px;
    }

    .MuiFormControl-root,
    ion-select {
      height: 40px;
      max-width: 220px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.14);
      background: rgba(255, 255, 255, 0.04);
      @media screen and (max-width: 768px) {
        max-width: unset;
      }
    }
  }

  .content-ctn {
    padding: 0px 0px 20px 0px;
    //background-color: var(--ion-card-background);
    border-radius: 6px;

    &.light-bg {
      //background-color: var(--reports-bg-color);
    }

    .tbl-ctn {
      border-radius: 8px 8px 16px 16px;
      border-top: 0;
      background: none !important;

      .text-capitalize {
        text-transform: capitalize;
      }

      @media screen and (max-width: 768px) {
        border-radius: 10px 10px 16px 16px;
        background: none !important;
      }
      .table-total-container {
        padding: 12px;
      }
      .MuiTableContainer-root {
        .MuiTable-root {
          background: none !important;
          .MuiTableHead-root {
            .MuiTableCell-head {
              color: var(--text-dark-color);
              font-family: var(--headers-font-family);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              padding-top: 0px !important;
            }
          }

          .MuiTableBody-root {
            //background-color: #fff;

            .MuiTableRow-root {
              //background-color: #fff;
              //border-bottom: 1.5px solid #D9D9D9 !important;
            }

            // }
          }
        }
      }

      // .no-data-row {
      //   background: #fff !important;
      // }
    }

    .tbl-ctn:not(.no-hov-style) {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            // .MuiTableRow-root:hover {
            //   background-color: rgba(255, 167, 36, 0.2) !important;
            // }
          }
        }
      }
    }

    .table-ctn,
    .tbl-ctn {
      .MuiTableContainer-root {
        .MuiTable-root {
          .MuiTableBody-root {
            .MuiTableRow-root:nth-child(even) {
              // background: var(--ion-card-background-contrast); for wallet bg
            }

            .MuiTableRow-root:hover {
              // background-color: rgba(255, 167, 36, 0.2) !important;
            }

            .MuiTableRow-root.back-odd-row {
              background: var(--back-odd-background);

              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-light);
                font-weight: 400;
                font-size: 12px;
                line-height: normal;
              }
            }

            .MuiTableRow-root.lay-odd-row {
              background: var(--lay-odd-background);

              .MuiTableCell-body,
              .MuiSvgIcon-root {
                color: var(--ion-text-light);
              }
            }
          }
        }
      }
    }
  }
  .header-ctn {
  }
  .header-ctn,
  .content-ctn {
    .points-type-toggle {
      background-color: transparent;
      border: 2px solid #636c89;
      display: inline-flex;
      height: 48px;
      border-radius: 4px;

      .MuiBottomNavigationAction-root {
        color: var(--ion-text-color);
        font-weight: 500;
        font-size: 16px;
        padding: 0px !important;

        &.Mui-selected {
          color: var(--ion-text-light);
          background: var(--ion-color-success);
        }
      }
    }
  }

  .no-data-row {
    background: rgba(27, 42, 68, 0.14);
    width: 100%;
    color: var(--text-dark-color) !important;
    font-family: var(--headers-font-family) !important;
    padding-left: 15px;
    font-family: var(--ion-font-family);
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    border-radius: 0 0 16px 16px;
    @media screen and (max-width: 920px) {
      font-size: 10px;
    }

    div {
      // opacity: 0.5;
      color: var(--text-dark-color);
    }
  }

  .MuiPaper-root .RobotoableBody-root .MuiTableCell-body {
    color: var(--input-text-color);
    font-family: var(--headers-font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    &.profit {
      color: var(--ion-color-success) !important;
    }

    &.loss {
      color: var(--ion-color-danger) !important;
    }
  }
}

.h-100 {
  height: 100%;
}

@media only screen and (max-width: 720px) {
  .admin-module-ctn {
    padding-bottom: 25px;
  }

  .reports-ctn {
    margin: 12px !important;
    // background-color: var(--reports-background-white) !important;
    margin-top: 0px !important; //for my wallet
    .header-ctn,
    .content-ctn {
      .points-type-toggle {
        border: 1px solid #636c89;
        height: 32px;
        border-radius: 4px;

        .MuiBottomNavigationAction-root {
          font-size: 14px;
          line-height: 17px;
          min-width: 70px;
        }
      }

      .filters,
      .filters-row {
        margin-top: 10px;
        font-size: 12px;

        ion-select,
        .MuiFormControl-marginNormal {
          height: 32px !important;
          background: none !important;
          border-radius: 6px;
        }

        .MuiInputBase-root .MuiInputBase-input {
          padding: 0 12px;
          font-size: 12px;
        }
      }
    }

    .header-ctn {
      padding-left: 10px;
      margin: 0px 0px 0px 0px !important;
      .page-title {
        font-weight: 400;
        font-size: 26px;
        padding-left: 10px !important;

        &.button-page-title {
          font-weight: 400;
          font-size: 18px;
          color: var(--ion-text-color);
          display: flex;
          align-items: center;
          margin-top: 16px;
          padding-left: 0 !important;

          .button-page-icon {
            padding-right: 4px;
          }
        }
      }

      .img-page-title-row {
        display: flex;
        align-items: center;

        .title-image {
          height: 21px;
          width: 21px;
        }

        .title {
          font-size: 18px;
          line-height: 22px;
          margin-left: 1px;
        }
      }

      .summary-card {
        ion-row:not(:last-child) {
          margin-bottom: 10px;
        }

        .summary-expand-toggle {
          text-align: center;

          .summary-expand-toggle-btn {
            color: var(--ion-text-color);
            text-transform: none;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.02em;
            height: 20px;

            ion-icon {
              color: var(--ion-text-color);
              height: 18px;
            }
          }
        }
      }
    }

    .tbl-ctn .MuiTableContainer-root .MuiTable-root {
      background: var(--webkit-gradient-background-1);
      background: var(--linear-gradient-background-1);
    }

    .content-ctn {
      .table-ctn {
        .MuiTableCell-root {
          padding: 8px;
        }
      }
    }

    .txt-info {
      font-size: 13px;
    }

    .mob-fs-13 {
      font-weight: bold !important;
      font-size: 13px;
      text-transform: capitalize;
    }

    .mob-fs-14 {
      font-weight: bold !important;
      font-size: 14px;
      text-transform: capitalize;
    }

    .mob-fs-16 {
      font-weight: bold !important;
      font-size: 16px;
      text-transform: capitalize;
    }
  }
}

.competitions-menu-tabs {
  .competitions-menu-nav-link {
    .MuiButtonBase-root {
      border-bottom: unset;
    }
  }

  .MuiButtonBase-root {
    opacity: 1;
    border-bottom: 1px solid var(--ion-primary-border-color);
    padding: 6px 0;
    max-width: unset;

    &:last-child {
      border-bottom: 0 !important;
    }

    .MuiTab-wrapper {
      opacity: 0.6;
    }

    &.active {
      .MuiTab-wrapper {
        color: var(--ion-color-primary);
        opacity: 1;
      }
    }
  }
}
