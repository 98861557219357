.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      .MuiDialogTitle-root {
        background: #0d192e;
        color: var(--ion-text-color);
        text-align: center;
        height: auto;
        padding: 10px 0;
        font-size: 24px;
        .MuiTypography-root {
          color: var(--ion-text-color);
        }
      }
      .MuiIconButton-root {
        position: absolute;
        top: 10px;
        right: 5px;
        opacity: 1;
        color: var(--input-background-color) !important;
      }
      .MuiIconButton-label {
        position: absolute;
        right: 25px;
        top: 60px;
        z-index: 9;
        background: #425062;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @media only screen and (max-width: 680px) {
          right: 3px;
          top: 240px;
        }
      }
      .eyeicon {
        .MuiIconButton-label {
          background: none;
          top: auto;
          background: none;
          top: -8px;
          right: 0;
        }
      }
      .light-bg-title {
        background: var(--ion-text-light);
        color: var(--dialog-header-background);
        text-align: center;
        height: 80px;
        .MuiTypography-root {
          color: var(--dialog-header-background);
        }
      }

      .MuiDialogContent-root {
        background: var(--dialog-content-background);
        padding: 20px 28px !important;

        .MuiButton-containedPrimary {
          background-color: var(--ion-color-primary);
          color: var(--ion-text-light) !important;
          text-transform: none;
          font-weight: 400;
          font-family: var(--headers-font-family) !important;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          line-height: normal;
          width: 100%;
        }
        .MuiButton-containedPrimary:hover {
          background-color: var(--ion-color-primary-shade);
        }
      }
    }
  }
}
