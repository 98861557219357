.forgot-pwd-modal {
  &.MuiDialog-root
    .MuiDialog-container
    .MuiDialog-paper
    .MuiDialogContent-root {
    display: block;
  }

  @media screen and (max-width: 920px) {
    height: fit-content;
    margin: auto 16px;
  }

  .MuiPaper-root {
    border-radius: 30px;
  }

  .MuiDialogTitle-root {
    font-size: 40px;
    line-height: 54px;
    
    text-align: left;
    padding: 5px 24px;

    .MuiTypography-root {
      font-size: 40px;
      line-height: 54px;
      
      text-align: left;

      @media screen and (max-width: 920px) {
        font-size: 24px;
        line-height: 54px;
      }
    }
  }
}

.forgot-pwd-form-ctn {
  height: 100%;
  display: flex;
  flex-direction: column;

  .usr-input,
  .code-input,
  .pwd-input {
    display: inline-grid;

    .MuiFormControl-root {
      margin: 0;
    }
  }

  .confirm-btn {
    margin-top: 10px !important;
    height: 60px;
    font-family: var(--headers-font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
    border-radius: 12px;

    @media screen and (max-width: 768px) {
      border-radius: 8px;
      height: 44px;
    }
  }

  .MuiIconButton-root {
    padding: 0;
    opacity: 1;
    color: var(--ion-text-color) !important;
  }

  .error-msg {
    color: var(--ion-color-danger);
    margin-top: 20px;
    text-align: center;
  }

  .success-msg {
    color: var(--ion-color-success);
    margin-top: 20px;
    text-align: center;
  }

  .usr-input,
  .pwd-input,
  .code-input {
    margin-top: 12px;
  }

  .user-name,
  .pwd-field {
    // max-width: 341px;
    border-radius: 10px;
    height: 48px;
    background-color: #eaeef3 !important;

    .MuiFormControl-root {
      margin: 0;
    }

    .MuiInputBase-root {
      background-color: #eaeef3 !important;
      border-radius: 10px;

      .MuiInputBase-input {
        border: 0;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
    }
  }
}

@media (min-width: 350px) and (max-width: 420px) {
  .user-name,
  .pwd-field,
  .login-form-btn {
    width: 100% !important;
  }
}

@media (min-width: 220px) and (max-width: 349px) {
  .user-name,
  .pwd-field,
  .login-form-btn {
    max-width: 100% !important;
  }
}
