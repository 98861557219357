.modal-close-btn {
  position: absolute !important;
  top: 8px;
  right: 8px;
  color: #9e9e9e;
}
.login-modal-ctn .MuiPaper-root {
  min-height: 520px !important;
  max-height: 520px !important;
  padding: 0px !important;
  margin: 0px;
  background: transparent;
  .MuiDialogContent-root {
    background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 0px;
  }
}

@media screen and (max-width: 720px) {
  .login-modal-ctn .MuiPaper-root {
    min-height: 100% !important;
    max-height: 100% !important;
    background-color: #00143d;
    padding: 15px !important;
    margin: 0px;
    .MuiDialogContent-root {
      background: none !important;
      //  background-position-y: center !important;
      background-position: top center !important;
      background-repeat: no-repeat !important;
      // background-size: contain !important;
      background-size: 100vw auto !important;
      background-attachment: fixed !important;
      display: block;
      justify-content: center;
      align-items: center;
    }
  }
}
.dark-colose-btn {
  color: var(--dialog-header-background) !important;
}

.dark-colose-btn .MuiIconButton-root {
  color: var(--dialog-header-background) !important;
}

.test {
  .MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiIconButton-label {
    right: 3px;
    color: var(--input-background-color);
    top: 0px !important;
  }
}

@media only screen and (max-width: 720px) {
  .MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiIconButton-label {
    right: 3px;
    top: 0px;
  }
  .login-btn-align {
    display: none !important;
  }
}

@media only screen and (min-width: 721px) {
  .MuiDialog-root .MuiDialog-container .MuiDialog-paper .MuiIconButton-label {
    right: 3px;
    top: 0px;
  }
  .login-btn-align {
    display: none !important;
  }
}
