.login-ctn {
  background-color: var(--site-background-color);
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  .PrivateNotchedOutline-legendLabelled-7 {
    max-width: 0px;
  }
  .PrivateNotchedOutline-legendLabelled-13 {
    max-width: 0px;
  }
  .title-row {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);

    .logo {
      max-width: var(--login-logo-xl-width);
      min-height: var(--login-logo-xl-height);
      margin-bottom: 20px;
    }
  }

  .login-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    @media only screen and (max-width: 720px) {
      position: initial;
      top: initial;
      left: initial;
      transform: initial;
      margin: 30px 15px;
    }
    .logo {
      max-width: var(--login-logo-xl-width);
      min-height: var(--login-logo-xl-height);
      margin-bottom: 20px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .login-ctn {
    background-color: var(--site-background-color);
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    .title-row {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .login-card {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media screen and (max-width: 600px) {
  .login-ctn {
    background-color: var(--ion-background) !important;
    background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .title-row {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    // .login-card {
    //   position: absolute;
    //   top: 40%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    // }
  }
}
@media only screen and (device-width: 360px) {
  .login-ctn {
    background-color: var(--ion-background);
    background-color: var(--ion-background);
    // background-position-y: center !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    .title-row {
      position: absolute;
      top: 20%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .login-card {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
