.MuiPaper-root {
  .MuiDialog-paper {
    margin: 0px;
    background: #ffffff;
  }
}

.set-password-ctn {
  .change-pwd-form-ctn {
    .changepass-content-block {
      // background: none; commented for reset paswrd
      .cp-form-block {
        width: 100%;
        @media screen and (max-width: 720px) {
          border-radius: 24px;
          background: var(--reports-background-white);
        }

        .accept-terms-field {
          display: flex;
          align-items: center;
          position: relative;
          .accept-terms-checkbox {
            position: absolute;
            left: 10px;
            top: -7px;
          }
          .MuiTypography-body1 {
            margin-left: 50px;
            color: var(--text-dark-color);
          }
        }
        .accept-terms-input {
          .accept-terms-field {
            .accept-terms-checkbox {
              .MuiIconButton-label {
                top: -8px;
                right: -20px;
              }
            }
          }
        }
      }
    }
  }
}
