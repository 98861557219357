.table-ctn {
  width: 100%;
  .MuiPaper-root {
    margin-top: 16px;
    box-shadow: none;
    border-color: none;
    width: 100%;
    border-radius: 6px;
    background-color: transparent;
    border-radius: 16px;

    .MuiTable-root {
      .MuiTableRow-root {
        background-color: rgba(255, 255, 255, 0.02);
        &.back-bg {
          background: var(--back-odd-background-light) !important;
        }

        &.lay-bg {
          background: var(--lay-odd-background) !important;
        }

        border: 1px solid var(--border-color-tables);

        @media screen and (max-width: 920px) {
          border-right: 0;
          border-left: 0;
          border-bottom: 1px solid #bcbcbc;
        }
      }

      .MuiTableHead-root {
        height: 18px;

        .MuiTableRow-root {
          color: var(--ion-text-dark);
          border-radius: 8px;
          // background: var(--reports-background-color);
          .MuiTableCell-root {
            border: 0px !important;
            font-family: var(--headers-font-family);
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            white-space: nowrap;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-body {
          font-family: var(--headers-font-family);
          color: var(--text-dark-color);
          border: 0px !important;
          cursor: default;
          white-space: nowrap;
          font-size: 12px;
        }

        .MuiSvgIcon-root {
          color: var(--text-dark-color);
        }
      }
    }
  }
}

.tbl-ctn {
  width: 100%;
  background: var(--webkit-gradient-background-2) !important;
  background: var(--linear-gradient-background-2) !important;

  .MuiPaper-root {
    box-shadow: none;
    border-color: none;
    width: 100%;
    background-color: transparent;
    border-radius: 16px;
    @media screen and (max-width: 920px) {
      border-radius: 10px 10px 0 0;
    }

    .MuiTable-root {
      background: var(--webkit-gradient-background-2) !important;
      background: var(--linear-gradient-background-2) !important;
    }

    .MuiTableRow-root {
      border: none;
    }

    .MuiTableBody-root {
      background: rgba(27, 42, 68, 0.14) !important;
    }

    .MuiTableHead-root {
      height: 18px;

      .MuiTableRow-root {
        .MuiTableCell-root {
          color: var(--ion-text-color);
          border: 0px !important;
          font-family: var(--headers-font-family);
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          white-space: nowrap;

          .MuiIconButton-root {
            opacity: 1;

            .MuiTableSortLabel-root .MuiSvgIcon-root {
              color: #fff !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-body {
        color: var(--text-dark-color);
        border: 0px !important;
        cursor: default;
        white-space: nowrap;
        font-size: 12px;
        font-family: var(--headers-font-family);
      }

      .MuiSvgIcon-root {
        color: var(--ion-text-color);
      }

      .back-odd-row {
        background-color: var(--back-odd-background);
        border-bottom: 1px solid #ffffff;
      }

      .lay-odd-row {
        background-color: var(--lay-odd-background);
        border-bottom: 1px solid #ffffff;
      }

      .profit-bet {
        color: var(--ion-color-success) !important;
      }

      .loss-bet {
        color: var(--ion-color-lost) !important;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .table-ctn {
    .MuiPaper-root {
      margin-top: 16px;

      .MuiTable-root {
        .MuiTableHead-root {
          .MuiSvgIcon-root {
            font-size: 20px;
          }
        }

        .MuiTableBody-root {
          .MuiTableCell-body {
            font-size: 12px;
            min-height: 48px;
          }

          .MuiSvgIcon-root {
            font-size: 18px;
            margin-bottom: -2px;
          }
        }
      }
    }
  }

  .tbl-ctn {
    .MuiPaper-root .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
      font-size: 12px !important;
      font-weight: 400;
      padding: 8px 12px;
    }
  }
}

// @media screen and (max-width: 720px) {
//   .tbl-ctn .MuiPaper-root .MuiTableHead-root {
//     border-radius: 8px;
//     background-color: #0d192e;
//     box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
//   }
// }
