@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './reports.scss';
@import './loadingCircle';
@import './search_filter.scss';
@import './scorecardWidgets.scss';

@import url('https://fonts.googleapis.com/css2?family=Alata&family=Jost:wght@300;400;500;600;700&family=Montserrat:wght@400;500;700&family=Roboto+Condensed:wght@400;700&display=swap');

// @font-face {
//   font-family: 'Roboto';
//   src: local('Roboto'), url('../fonts/Roboto-Regular.ttf') format('truetype');
// }

/* Backdrop styles */
.MuiBackdrop-root {
  // background: rgba(72, 99, 155, 0.85) !important;
}
.multi-add-icon {
  height: 30px;
  cursor: pointer;
}
.multi-remove-icon {
  height: 30px;
  cursor: pointer;
}
/* Input Styles */
.MuiFormHelperText-contained {
  margin: 0px !important;
  background: none !important;
}

.MuiInputBase-root .MuiFormControl-root,
.MuiOutlinedInput-root {
  background: var(--input-background-color) !important;
  color: var(--input-text-color) !important;
  border-radius: 4px;
}

.MuiInputBase-root .Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.MuiInputBase-root {
  // color: var(--input-text-color) !important;
  font-family: 'Alata';
  font-size: 14px;
  height: 48px;
  padding: 0;

  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 0px;
    font-size: 14px;
    font-family: var(--headers-font-family);
    padding: 14px 16px;
    // color: var(--text-dark-color);
    color: white;
  }
  .MuiSvgIcon-root {
    color: var(--input-text-color);
    fill: rgba(24, 187, 78, 1);
  }
}

.input-label {
  font-family: 'Alata';
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 6px;
}

// Modal styles
.modal-title {
  .MuiTypography-root {
    font-family: 'Alata' !important;
    font-size: 28px !important;
    color: #ffffff;
  }
}

.modal-content-ctn {
  background-color: var(--home-main-blocck-bg) !important;
  background: var(--home-main-blocck-bg) !important;

  .date-control,
  .input-control {
    border: 1px solid var(--filter-controls-border);
  }
}

.hand-pt {
  cursor: pointer;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--input-text-color) !important;
  -webkit-transition: background-color 9999s ease-out !important;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-color-primary);
  text-transform: capitalize;
}
.MuiTab-root {
  text-transform: none;
}
.MuiTabs-indicator {
  display: none;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.sticky-col {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.b-text {
  font-weight: 400;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.tiny-info-text {
  font-size: 12px;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.left-align {
  align-items: left !important;
}

.m-link {
  cursor: pointer !important;
}

.profit {
  color: var(--ion-color-success);
}

.loss {
  color: var(--ion-color-danger);
}

// Carousel styles
.carousel .slide {
  background: none !important;
}

@keyframes color-change {
  0% {
    color: red;
    font-weight: bolder;
  }
  50% {
    color: var(--ion-color-success);
    font-weight: 400;
  }
  100% {
    color: var(--ion-color-primary);
    font-weight: bolder;
  }
}

@keyframes flashing-text {
  0% {
    color: red;
    transform: scale(1.2);
  }
  25% {
    color: var(--ion-color-success);
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    color: red;
    transform: scale(1);
  }
  100% {
    color: var(--ion-color-success);
    transform: scale(1.2);
  }
}

//Disable step buttons of number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

.payments-drawer {
  .MuiDrawer-paper {
    background: var(--drawer-background) !important;
    width: 60%;
    max-width: 410px;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 5px !important;
}

table tr td:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
table tr td:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
table thead tr {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: hidden;
}

.MuiTableCell-root {
  font-family: 'Alata' !important;
}
.MuiTab-root {
  font-family: 'Alata' !important;
}
.MuiToolTip-popper {
  font-family: 'Alata' !important;
}
.MuiTypography-body1 {
  font-family: 'Alata' !important;
}
.MuiButton-root {
  font-family: 'Alata' !important;
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none !important;
}
