.login-form-page {
  width: 400px;
  position: relative;
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
  .title-row {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .logo {
      width: 130px;
    }
  }

  .login-form-ctn {
    padding: 25px 35px;
    background: #203252;
    border-radius: 24px;
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -20px) scale(0.75);
      color: #fff;
      background: none;
      font-size: 16px;
    }
    .PrivateNotchedOutline-legendLabelled-3 {
      display: none;
    }
    .PrivateNotchedOutline-legendLabelled-13 {
      max-width: 0;
    }
    .MuiInputBase-root .MuiSvgIcon-root {
      fill: #000;
    }
    .PrivateNotchedOutline-root-1 {
      top: 0;
    }
    @media only screen and (max-width: 720px) {
      padding: 20px 15px;
    }
    .MuiOutlinedInput-root {
      border-radius: 12px;
    }
    .card-title {
      color: var(--ion-color-secondary-contrast);
      font-weight: normal;
      text-align: center;
      margin-bottom: 20px;
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-family: var(--headers-font-family);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .page-title {
      display: flex;
      justify-content: center;
      font-size: 24px;
      line-height: 24px;
      color: var(--ion-color-secondary-contrast);
      font-family: var(--headers-font-family);
    }

    .usr-input,
    .code-input,
    .pwd-input {
      display: inline-grid;
      width: 100%;
      .input-labell {
        color: #fff !important;
      }
    }
    .pwd-input {
      margin-top: 22px;
      width: 100%;
    }

    .form-control {
      margin: 10px 0;
      .MuiFormControl-root {
        background: #1f2e52 !important;
        .MuiInputBase-root {
          background: #1f2e52 !important;
          color: #fff !important;
        }
      }
    }

    .MuiInputBase-input {
      font-size: 16px;
      font-family: var(--headers-font-family);
      color: black;
    }

    .MuiIconButton-root {
      padding: 0;
      opacity: 0.4;
    }

    .login-err-msg {
      color: red;
      margin-top: 20px;
      text-align: center;
      max-width: 250px;
    }

    .user-name,
    .pwd-field {
      height: 48px;
      .input-label {
        color: #fff !important;
      }
    }

    .login-btns {
      display: block;
      justify-content: center;
      align-items: center;
      .login-form-btn {
        height: 48px;
        width: 100%;
        background: var(--ion-color-primary);
        color: var(--ion-text-light) !important;
        margin-top: 15px;
        border-radius: 100px;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        @media screen and (max-width: 720px) {
        }
      }
    }

    .bottom-link {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 6px;
      font-size: 16px;
      color: var(--ion-text-light);
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
      text-align: center;
      font-family: var(--headers-font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.800000011920929;
      @media screen and (max-width: 720px) {
        font-family: 14px;
      }
      .register-link {
        color: var(--ion-color-secondary-contrast);
        cursor: pointer;
      }
    }
  }
  .loginFooter {
    background: linear-gradient(0deg, #203252 0%, rgba(13, 25, 46, 1) 100%);
    border-radius: 0px 0px 24px 24px;
    padding: 15px 0;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    display: none;
    font-family: var(--headers-font-family);
    @media screen and (max-width: 720px) {
      padding: 14px 0;
      font-size: 14px;
    }
    span {
      margin-right: 2px;
      color: #18bb4e;
      cursor: pointer;
      font-family: var(--headers-font-family);
    }
  }
  .close-btn {
    background: #425062;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: absolute;
    right: -12px;
    top: -20px;
    text-align: center;
    color: #fff;
    font-size: 22px;
    padding-top: 10px;
    cursor: pointer;
    @media only screen and (max-width: 720px) {
      display: none;
    }
  }
}

// @media screen and (max-width: 720px) {
//   .login-form-page {
//     width: 100%;
//     padding: 0px;
//     .title-row {
//       display: flex;
//       justify-content: center;
//       margin-top: 30px;

//       .logo {
//         width: 130px;
//       }
//     }

//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 12rem;
//       padding: 10px 18px !important;
//       margin-left: 15px;
//       .user-name,
//       .pwd-field,
//       .login-form-btn {
//         max-width: 274px;
//       }
//     }
//   }
// }
// @media only screen and (device-width: 360px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 12rem;
//       padding: 10px 18px !important;
//       margin-left: 0px;
//       .login-btns {
//         display: flex;
//         width: 100%;
//         align-items: center;
//       }
//     }
//   }
// }
// @media only screen and (device-width: 411px) and (device-height: 823px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 10rem;
//       padding: 10px 18px !important;
//       .login-btns {
//         display: flex;
//         align-items: center;
//         width: 100%;
//         margin-top: 5px;
//       }
//     }
//   }
// }
// @media only screen and (device-width: 375px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 12rem;
//       padding: 10px 18px !important;
//       margin-left: 0px;
//       .login-btns {
//         display: flex;
//         width: 100%;
//         align-items: center;
//       }
//     }
//   }
// }
// @media only screen and (min-device-width: 350px) and (device-height: 420px) {
//   .login-form-page {
//     .login-form-ctn {
//       min-width: 314px !important;
//       max-width: 314px !important;
//       margin-top: 12rem;
//       padding: 10px 18px !important;
//       .login-btns {
//         display: flex;
//         align-items: center;
//       }
//     }
//   }
// }
// @media only screen and (max-device-width: 320px) {
//   .login-form-page {
//     .title-row {
//       display: flex;
//       justify-content: center;
//       margin-top: 35px;

//       .logo {
//         width: 130px;
//       }
//     }

//     .login-form-ctn {
//       min-width: 250px !important;
//       max-width: 250px !important;
//       margin-top: 10rem;
//       padding: 10px 18px !important;

//       .page-title {
//         font-size: 16px;
//         line-height: 16px;
//       }

//       .MuiInputBase-input {
//         font-size: 13px;
//         font-family: 'Roboto';
//       }

//       .user-name,
//       .pwd-field {
//         height: 38px;
//       }
//       // .user-name,
//       // .pwd-field,
//       // .login-form-btn {
//       //   max-width: 250px;
//       // }

//       .login-form-btn {
//         height: 30px;
//         font-size: 13px;
//       }
//       .login-btns {
//         display: flex;
//         align-items: center;
//       }
//     }
//   }
// }
